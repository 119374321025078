<template>
  <div>
    <h1>New account</h1>

    <div v-if="error_text" class="w-full lg:w-1/2 my-4 text-red-600 bg-red-200 p-6 pt-4 pb-4 bg-white rounded shadow-md leading-loose">
        <strong>Something went wrong:</strong> <span v-html="error_text"></span>
    </div>

    <div class="w-full lg:w-1/2 my-4">
        <form @submit="submitForm" autocomplete="off" class="pt-2 p-6 bg-white rounded shadow-lg leading-loose">
            <div>
                <label class="block text-gray-600 py-3">
                    Account Name:
                </label>
                <input class="w-full px-3 py-1 text-gray-700 bg-gray-200 rounded" type="text" v-model="name" placeholder="My Company">
            </div>
            <div>
                <label class="block text-gray-600 py-3">
                    Account Purpose:
                </label>
                <select v-model="purpose" class="w-full h-9 px-3 py-1 text-gray-700 bg-gray-200 rounded">
                <option value="MESSAGES">Messages</option>
                <option value="SCRAPING">Scraping</option>
                </select>
            </div>
            <div>
                <label class="block text-gray-600 py-3">
                    <strong>IG - </strong> Username:
                </label>
                <input class="w-full px-3 py-1 text-gray-700 bg-gray-200 rounded" type="text" v-model="username" placeholder="my_cool_company">
            </div>
            <div>
                <label class="block text-gray-600 py-3">
                     <strong>IG - </strong> Password:
                </label>
                <input class="w-full px-3 py-1 text-gray-700 bg-gray-200 rounded" type="password" v-model="password">
            </div>
            <div class="w-full p-2 mt-4 text-sm text-yellow-600 bg-yellow-100 text-center rounded">
                ⚠️ The password is only used to get a new session ID - we don't save it at any time.
            </div>
            <div class="mt-4">
                <button class="px-4 py-1 text-white font-light tracking-wider bg-gray-900 rounded" type="submit">Create account</button>
            </div>
        </form>
    </div>

  </div>
</template>

<script>
import axios from 'axios'

export default {
  data() {
    return {
        name: null,
        purpose: 'MESSAGES',
        username: null,
        password: null,
        error_text: null,
    }
  },
  methods: {
    submitForm(evt) {
      evt.preventDefault();
      evt.submitter.disabled = true;
      evt.submitter.innerText = 'Please be patient...';
      
      axios.post(`${process.env.VUE_APP_API_URL}/accounts`, {
            name: this.name,
            purpose: this.purpose,
            username: this.username,
            password: this.password
        })
        .then(response => {
            this.$router.push(`/accounts/${response.data.id}`)
        })
        .catch(error => {
            evt.submitter.disabled = false;
            evt.submitter.innerText = 'Create account';

            switch (error.response.data.status)
            {
                case 'wrong-credentials':
                    this.error_text = 'Invalid username or password.';
                    break;
                case 'suspisious-login':
                    this.error_text = 'Instagram has detected a suspisious login on your account. Please <a href="https://www.instagram.com/session/login_activity/" target="_blank" class="underline">click here</a> and verify it was you there tried from "Mountain View, California" - after your confirmation, please try to create the account again.';
                    break;
                default:
                    this.error_text = 'An unknown error. Please try again.'
                    break;
            }
        });
    }
  }
}
</script>